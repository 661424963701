import React from 'react';
import Login from './Pages/Login'
import Listing from './Pages/Listing';
import Edit from './Pages/Edit'
import Add from './Pages/Add'
import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
import ProtectedRoutes from './components/ProtectedRoutes';

function App() {
    return ( 
        <Router>
			<Routes>
                <Route element={<ProtectedRoutes/>}>
                    <Route path = "/" element={<Listing/>} />
                    <Route path = "/edit/:id" element={<Edit/>} />
                    <Route path = "/add" element={<Add/>} />
                </Route>
                <Route path = "/login" element={<Login/>} />
			</Routes> 
        </Router>
    );
}

export default App;
