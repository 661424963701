import React from "react";
import Header from "../components/Header";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Levels from "../components/Levels";

const  Add = () => {
    const [id, setId] = useState('');
    const [category, setCategory] = useState('');
    const [image, setImage] = useState('');
    const [name, setName] = useState('');
    const [benefits, setBenefits] = useState('');
    const [description, setDescription] = useState('');
    const [level, setLevel] = useState('Beginner')
 
    const bold = {
        fontWeight : '600'
    }

    const handleSave = (event) => {
        event.preventDefault();
        const data = {
            id : id,
            name : name,
            image : image,
            category : category,
            description : description,
            benefits : benefits,
            image : image,
            level : level
        };
        const endpoint = process.env.REACT_APP_BASE_URL + '/api/add.php';
        fetch(endpoint, {
            "method": "POST",
            "body": JSON.stringify(data)
        })
        .then(response => response.json())
        .then(response => {     
            if(response.action === "failed"){
                toast.error(response.msg, {
                    position: toast.POSITION.TOP_RIGHT,
                    theme: "colored"
                });
            }else{
                toast.success(response.msg, {
                    position: toast.POSITION.TOP_RIGHT,
                    theme: "colored"
                });
                reset();
            }
        })
        .catch(err => {
            console.log(err);
        });
    };

    const reset = () => {
        setImage('');
        setName('');
        setId('');
        setCategory('');
        setBenefits('');
        setDescription('');
        setLevel('');
    }

   
    return (
        <>
        <ToastContainer />
        <Header page="Add"/> 
        {
            <div className="container-fluid small">
                <form style={{ marginTop:'30px' }}>
                    <div className="row">
                        <div className="col">
                            <div className="d-flex justify-content-center">
                                <img src={image} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="form-group mb-3">
                                <label style={bold}>ID <span className="text-danger">*</span> ( Yoga_XXXXXXXXXXXXXXXXXXXXXXXX )</label>
                                <div className="controls">
                                    <input type="text" className="form-control small" value={id} onChange={(e) => setId(e.target.value)}/>
                                </div>
                            </div>

                            <div className="form-group mb-3">
                                <label style={bold}>Image</label>
                                <div className="controls">
                                    <input type="text" className="form-control small" value={image} onChange={(e) => setImage(e.target.value)}/>
                                </div>
                            </div>

                            <div className="form-group mb-3">
                                <label style={bold}>Name</label>
                                <div className="controls">
                                    <input type="text" className="form-control small" value={name} onChange={(e) => setName(e.target.value)}/>
                                </div>
                            </div>
                        
                            <div className="form-group mb-3">
                                <label style={bold}>Category</label>
                                <div className="controls">
                                    <input type="text" className="form-control small" value={category} onChange={(e) => setCategory(e.target.value)}/>
                                </div>
                            </div>

                            <div className="form-group mb-3">
                                <label className="required" style={bold}>Level</label>
                                <select className="form-control" value={level} onChange={(e) => setLevel(e.target.value)}>
                                    {
                                        Levels.map((item, i) => (
                                            <option value={item} key={i} >{item}</option>
                                        ))
                                    }
                                </select>
                            </div>

                            <div className="form-group mb-3">
                                <label style={bold}>Description</label>
                                <div className="controls">
                                    <textarea rows={2} className="form-control small" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
                                </div>
                            </div>

                            <div className="form-group mb-3">
                                <label style={bold}>Benefits</label>
                                <div className="controls">
                                    <textarea rows={2} className="form-control small" value={benefits} onChange={(e) => setBenefits(e.target.value)}></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                     
                    <div className="row justify-content-center">
                        <div className="col-md-1 col-md-offset-1">
                            <button type="button" id="save" onClick={handleSave} className="btn btn-primary">Save</button>
                        </div>
                    </div>
                </form>
            </div>
        }
        </>
     );
}
 
export default Add;